import { Field } from 'redux-form'
import { Classes } from 'jss'
import { GroupedOptionsType, OptionsType, ValueType } from 'react-select/lib/types'

export interface OptionType {
  label: string | number
  value: string | number
}

export interface SelectPropsType {
  options: GroupedOptionsType<OptionType> | OptionsType<OptionType>
  customOptionComponent?: any
  customSingleValueComponent?: any
  title?: string
  name: string
  onInputChange?: (val: string) => void
  withoutBorder?: boolean
  placeholder?: string
  disabled?: boolean
  isCity?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  noOptionsMessage?: string
  withAvatar?: boolean
  filterOption?: any
  defaultValue?: ValueType<OptionType>
  customLayoutClasses?: Classes | string
  menuPortalTarget?: HTMLElement
  menuIsOpen?: boolean
  isMulti?: boolean
}

export const FieldSelect = Field as new () => Field<SelectPropsType>
