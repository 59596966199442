import React, { FC } from 'react'
import cx from 'classnames'
// types
import { Classes } from 'jss'
// styles
import { sheet } from './sheet'
import injectSheet from 'react-jss'
// assets
import AvatarIcon from 'components/svgComponent/notification/AvatarIcon'

interface ExternalProps {
  src?: string | null
  tooltipText?: string
  name?: string
  className?: Classes | string
  onClick?: () => void
}

interface OwnProps {
  classes: Classes
}

type Props = OwnProps & ExternalProps

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS || ''

const Avatar: FC<Props> = ({ classes, src, name = '', tooltipText = '', className, onClick }) => {
  return (
    <>
      {src ? (
        <img
          className={cx(classes.img, className)}
          src={src.includes(API_URL) ? src : `${API_URL}/${src}`}
          title={tooltipText || ''}
          onClick={onClick}
        />
      ) : (
        <AvatarIcon className={cx(classes.img, className)} onClick={onClick} title={tooltipText} />
      )}
    </>
  )
}

export default injectSheet(sheet)(Avatar)
