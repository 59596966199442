import React, { FC, memo } from 'react'
// components
import Layout from '../Layout'
// redux
import { WrappedFieldProps } from 'redux-form'
import ReactSelect, { components as reactSelectComponents } from 'react-select'
// types
import { SelectPropsType } from 'components/Form/Fields/FieldSelect'
import { StyledComponentProps } from 'react-jss'
import { CustomOptionProps } from './CusotmOptionRecruiter'
import { CustomSingleValueProps } from './CustomSingleRecruiter'
// styles
import { colourStylesWithoutBorder, colourStyles, DropdownIndicator } from './style'

type Props = SelectPropsType &
  WrappedFieldProps &
  StyledComponentProps & {
    customOptionComponent?: React.ComponentType<CustomOptionProps>
    customSingleValueComponent?: React.ComponentType<any>
  }

const Select: FC<Props> = props => {
  const {
    title,
    name,
    options,
    input: { value, onChange },
    meta,
    withoutBorder,
    placeholder,
    disabled,
    onInputChange,
    filterOption,
    isCity,
    isClearable = false,
    isSearchable = true,
    noOptionsMessage = null,
    defaultValue,
    customLayoutClasses,
    menuPortalTarget,
    menuIsOpen,
    isMulti = false,
    customOptionComponent,
    customSingleValueComponent
  } = props

  const isError = meta.touched && meta.error

  return (
    <Layout customClasses={customLayoutClasses} title={title} meta={meta} id={name}>
      <ReactSelect
        menuIsOpen={menuIsOpen}
        menuPortalTarget={menuPortalTarget}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        styles={withoutBorder ? colourStylesWithoutBorder : colourStyles}
        onInputChange={onInputChange}
        hideSelectedOptions={true}
        components={{
          DropdownIndicator,
          Option: customOptionComponent || reactSelectComponents.Option,
          SingleValue: customSingleValueComponent || reactSelectComponents.SingleValue
        }}
        noOptionsMessage={() => (isCity ? 'Start typing at least 2 characters' : noOptionsMessage)}
        placeholder={placeholder || 'Select...'}
        isClearable={isClearable || isCity}
        isSearchable={isSearchable}
        filterOption={filterOption}
        isMulti={isMulti}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral10: isError ? '#dc233b' : '#E6E6E6',
            primary25: 'rgba(243, 243, 243, 0.3)'
          }
        })}
      />
    </Layout>
  )
}

export default memo(Select)
